<template functional>
  <el-dropdown trigger="click">
    <el-button
      plain
      circle
    >
      <fai icon="bars"/>
    </el-button>

    <el-dropdown-menu
      slot="dropdown"
      class="menu"
    >
      <el-dropdown-item
        v-if="parent.$store.state.auth.user"
        class="user"
      >
        <div class="avatar-wrapper">
          <el-avatar
            :size="50"
            :src="$options.picture(parent)"
            icon="el-icon-fa-user fad"
          />
        </div>

        <div class="d-flex">
          <el-button
            class="bg-gd-pm"
            size="small"
            @click="$options.openDashboard(parent)"
          >
            <fai icon="chart-line"/>
            {{ parent.$t('dashboard') }}
          </el-button>

          <el-button
            size="small"
            class="bg-gd-dn"
            @click="parent.$auth.logout()"
          >
            <fai icon="sign-out"/>
            {{ parent.$t('logout') }}
          </el-button>
        </div>
      </el-dropdown-item>
      <el-dropdown-item>
        <router-link to="about">
          <fai icon="info-circle"/>

          {{ parent.$t('about') }}
        </router-link>
      </el-dropdown-item>
      <el-dropdown-item>
        <router-link to="features">
          <fai icon="phone-laptop"/>

          {{ parent.$t('features') }}
        </router-link>
      </el-dropdown-item>
      <el-dropdown-item v-if="!parent.$store.state.auth.user">
        <router-link to="pricing">
          <fai icon="money-check-alt"/>

          {{ parent.$t('pricing') }}
        </router-link>
      </el-dropdown-item>
      <el-dropdown-item>
        <router-link to="tutorials">
          <fai icon="book-open"/>

          {{ parent.$t('tutorials') }}
        </router-link>
      </el-dropdown-item>
      <el-dropdown-item>
        <router-link to="faq">
          <fai icon="question-circle"/>

          {{ parent.$t('faq') }}
        </router-link>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  openDashboard(parent) {
    parent.$router.push({
      name: 'app',
    });
  },

  picture(parent) {
    const { picture } = parent.$store.state.auth.user;

    if (!picture) {
      return null;
    }

    // TODO: Handle picture

    // return `${config['storage-server']}/${picture}`;
    return 'hello';
  },
};
</script>

<style lang="scss" scoped>
@import "src/home/styles/var";

.user {
  min-width: 180px;
  padding: 10px;
  border-radius: 4px 4px 0 0;
  cursor: default;
  text-align: center;
  background: $bg-2;
}

.avatar-wrapper {
  margin-bottom: 15px;
}

.menu {
  padding-top: 0;
}

.el-dropdown-menu__item {
  line-height: 0;

  a {
    display: inline-block;
    width: 100%;
    line-height: 36px;
    color: $--color-text-primary;
    text-decoration: none;
    transition: none;
  }

  &:hover a {
    color: $--color-primary;
  }
}
</style>
