import { render, staticRenderFns } from "./desktop-avatar.vue?vue&type=template&id=44b12d74&functional=true&"
import script from "./desktop-avatar.vue?vue&type=script&lang=js&"
export * from "./desktop-avatar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.6_0155b17f9cd7b4deb87a07278d29cc29/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports