import { render, staticRenderFns } from "./header.vue?vue&type=template&id=461d3542&scoped=true&"
import script from "./header.vue?vue&type=script&lang=js&"
export * from "./header.vue?vue&type=script&lang=js&"
import style0 from "./header.vue?vue&type=style&index=0&id=461d3542&lang=scss&scoped=true&"
import style1 from "./header.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.6_0155b17f9cd7b4deb87a07278d29cc29/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461d3542",
  null
  
)

export default component.exports