import ElDropdownMenu from 'element-ui/packages/dropdown/src/dropdown-menu';
import ElDropdownItem from 'element-ui/packages/dropdown/src/dropdown-item';
import ElDropdown from 'element-ui/packages/dropdown/src/dropdown';
import ElDialog from 'element-ui/packages/dialog/src/component';
import ElTabPane from 'element-ui/packages/tabs/src/tab-pane';
import ElButton from 'element-ui/packages/button/src/button';
import ElDivider from 'element-ui/packages/divider/src/main';
import ElAvatar from 'element-ui/packages/avatar/src/main';
import ElRadio from 'element-ui/packages/radio/src/radio';
import ElInput from 'element-ui/packages/input/src/input';
import ElForm from 'element-ui/packages/form/src/form';
import ElTabs from 'element-ui/packages/tabs/src/tabs';
import { emitter } from '@/plugins/emitter/emitter';
import ElFormItem from 'element-ui/lib/form-item';
import message from 'element-ui/packages/message';
import ElAlert from 'element-ui/packages/alert';
import ElTooltip from 'element-ui/lib/tooltip';
import ElSelect from 'element-ui/lib/select';
import ElOption from 'element-ui/lib/option';
import { i18n } from '@/home/i18n';

export default {
  install(Vue) {
    Vue.prototype.$requiredRule = Object.freeze({
      required: true,
      get message() {
        return i18n.t('validation-required');
      },
    });
    Vue.prototype.$max255Rule = Object.freeze({
      max: 255,
      trigger: 'blur',
      get message() {
        return i18n.t('validation-max', { length: i18n.n(255) });
      },
    });
    Vue.prototype.$message = message;

    emitter.on('$error-message', (key) => {
      message({
        type: 'error',
        duration: 6000,
        message: i18n.t(`error.${key}`),
      });
    });

    Vue.component('el-dropdown-menu', ElDropdownMenu);
    Vue.component('el-dropdown-item', ElDropdownItem);
    Vue.component('el-form-item', ElFormItem);
    Vue.component('el-dropdown', ElDropdown);
    Vue.component('el-tab-pane', ElTabPane);
    Vue.component('el-tooltip', ElTooltip);
    Vue.component('el-divider', ElDivider);
    Vue.component('el-button', ElButton);
    Vue.component('el-avatar', ElAvatar);
    Vue.component('el-dialog', ElDialog);
    Vue.component('el-option', ElOption);
    Vue.component('el-select', ElSelect);
    Vue.component('el-radio', ElRadio);
    Vue.component('el-input', ElInput);
    Vue.component('el-alert', ElAlert);
    Vue.component('el-tabs', ElTabs);
    Vue.component('el-form', ElForm);
  },
};
