<template>
  <header>
    <router-link
      class="h-100 d-flex align-items-center home"
      v-if="homeAndMenuVisible"
      to="/"
    >
      <img
        src="../../../assets/images/logo.svg"
        :alt="$t('app-title')"
      >
    </router-link>

    <nav v-if="breakpoints['md-and-up'].matches">
      <desktop-menu/>
    </nav>

    <div
      v-if="searchVisible"
      class="search-wrapper d-flex"
    >
      <el-input
        class="search-input"
        @blur="hideSearch"
        ref="input"
        :placeholder="$t('search')"
      />
      <button class="el-button search-btn bg-gd-pm">
        <fai icon="search"/>
      </button>
    </div>

    <div
      class="mobile-nav"
      v-if="!breakpoints['md-and-up'].matches"
    >
      <el-button
        v-if="!searchVisible"
        class="mobile-search-btn"
        @click="showSearch"
        plain
        circle
      >
        <fai icon="search"/>
      </el-button>

      <nav v-if="homeAndMenuVisible">
        <mobile-menu/>
      </nav>
    </div>

    <div
      v-if="breakpoints['md-and-up'].matches && user"
      class="avatar"
    >
      <desktop-avatar/>
    </div>
  </header>
</template>

<script>
import DesktopAvatar from './desktop-avatar';
import DesktopMenu from './desktop-menu';
import MobileMenu from './mobile-menu';

export default {
  components: {
    DesktopAvatar,
    DesktopMenu,
    MobileMenu,
  },

  data() {
    return {
      searchVisible$: false,
    };
  },

  computed: {
    breakpoints() {
      return this.$store.state.style.breakpoints;
    },

    user() {
      return this.$store.state.auth.user;
    },

    searchVisible() {
      if (this.breakpoints['md-and-up'].matches) {
        return true;
      }

      return this.searchVisible$;
    },

    homeAndMenuVisible() {
      if (this.breakpoints['md-and-up'].matches) {
        return true;
      }

      return !this.searchVisible$;
    },
  },

  methods: {
    hideSearch() {
      if (!this.breakpoints['md-and-up'].matches) {
        this.searchVisible$ = false;
      }
    },

    showSearch() {
      this.searchVisible$ = true;

      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/home/styles/var";

header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
  min-height: $header-height;
  padding: $header-padding;
  background: $bg-2;
  box-shadow: 0 0 5px rgba(0, 0, 0, .2);
}

img {
  height: $header-height - 10;
}

.mobile-nav {
  display: flex;
  margin-left: auto;
}

.mobile-search-btn {
  margin: 0 10px;
}

.avatar {
  margin-left: 50px;
}

.search-wrapper {
  width: 100%;
}

.search-btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

@media all and (min-width: $--md) {
  header {
    padding: $header-padding 20px $header-padding 20px;
  }

  .home {
    margin-right: 30px;
  }

  .search-wrapper {
    width: auto;
  }

  .search-input {
    width: 350px;
  }
}
</style>

<style>
.search-input input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}
</style>
