import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=348a2494&scoped=true&"
import script from "./footer.vue?vue&type=script&lang=js&"
export * from "./footer.vue?vue&type=script&lang=js&"
import style0 from "./footer.vue?vue&type=style&index=0&id=348a2494&lang=scss&scoped=true&"
import style1 from "./footer.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.6_0155b17f9cd7b4deb87a07278d29cc29/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "348a2494",
  null
  
)

export default component.exports