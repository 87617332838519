import Vue from 'vue';

import '@/home/styles/main.scss';
import '@/styles/fonts.scss';

import { apolloProvider } from '@/bootstrap/apollo-client';
import { initializeStore } from '@/bootstrap/init';
import askPassword from '@/plugins/ask-password';
import VueApollo from 'vue-apollo';
import Fai from '@/components/fai';
import auth from '@/plugins/auth';
import router from './router';
import plugin from './plugin';
import { i18n } from './i18n';
import store from './store';
import App from './layout';

Vue.use(askPassword);
Vue.use(VueApollo);
Vue.use(plugin);
Vue.use(auth);

Vue.component('fai', Fai);

new Vue({
  apolloProvider,
  router,
  store,
  i18n,
  render(h) {
    return h(App);
  },
  created() {
    initializeStore(store);
  },
}).$mount('#app');
