<template>
  <main>
    <partial-header/>

    <transition
      mode="out-in"
      name="fade"
    >
      <router-view/>
    </transition>

    <partial-footer/>
  </main>
</template>

<script>
import PartialHeader from './partials/header';
import PartialFooter from './partials/footer';

export default {
  components: {
    PartialHeader,
    PartialFooter,
  },
};
</script>
