<template>
  <footer>
    <div class="top">
      <div class="col-1">
        <div class="branding-wrapper">
          <div class="text-center">
            <img
              class="logo"
              src="../../../assets/images/logo.svg"
              :alt="$t('app-title')"
            >
          </div>
          <div class="w-100 social-links">
            <a
              href="https://youtube.com"
              title="Youtube"
            >
              <fai
                icon="youtube"
                type="fab"
              />
            </a>
            <a
              href="https://facebook.com"
              title="Facebook"
            >
              <fai
                icon="facebook-square"
                type="fab"
              />
            </a>
            <a
              href="https://twitter.com"
              title="Twitter"
            >
              <fai
                icon="twitter"
                type="fab"
              />
            </a>
          </div>
        </div>
      </div>

      <div>
        <h5>Company</h5>
        <nav>
          <ul>
            <li>
              <a href="">About</a>
            </li>
            <li>
              <a href="">Contacts</a>
            </li>
            <li>
              <a href="">News</a>
            </li>
            <li>
              <a href="">Careers</a>
            </li>
          </ul>
        </nav>
      </div>

      <div>
        <h5>Resources</h5>
        <nav>
          <ul>
            <li>
              <a href="">Sales Support</a>
            </li>
            <li>
              <a href="">Product Support</a>
            </li>
            <li>
              <a href="">Documentation</a>
            </li>
            <li>
              <a href="https://google.com">Blog</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="bottom d-flex justify-between align-items-center">
      <small>&copy; Copyright 2020 SAHARA IT All Rights Reserved</small>

      <div id="lang-switcher">
        <el-select
          v-model="locale"
          @change="changeLocale"
          size="medium"
        >
          <el-option
            v-for="item in $i18n.locales"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          />
        </el-select>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      locale: this.$i18n.locale,
    };
  },

  watch: {
    '$i18n.locale': function (value) {
      this.locale = value;
    },
  },

  methods: {
    changeLocale(locale) {
      this.$i18n.changeLocale(locale);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/var";

$color: hsla(0, 0%, 100%, 0.8);

footer {
  display: grid;
  background: $bg-dark;
}

.logo {
  height: 66px;
}

.top {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin: 20px;
}

.branding-wrapper {
  display: inline-block;
  width: min-content;
}

.social-links {
  display: flex;
  justify-content: center;
  grid-gap: 0 12px;
  margin-top: 26px;

  a {
    display: flex;
    padding: 10px;
    border-radius: 50%;
    font-size: 1em;
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    text-decoration: none;
  }

  a[href*="youtube"] {
    color: #ff0000;
  }

  a[href*="linked"] {
    color: #0077b5;
  }

  a[href*="facebook"] {
    color: #3b5998;
  }

  a[href*="twitter"] {
    color: #1da1f2;
  }
}

h5 {
  color: #fff;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;

  a {
    color: $color;
    font-weight: 300;
    font-size: .85em;
  }
}

.location {
  color: $color;
}

.bottom {
  padding: 10px;
  border-top: 1px solid darken($bg-dark, 3%);
}

small {
  color: #bfbdbd;
}
</style>

<style lang="scss">
@import "src/styles/var";

#lang-switcher {
  .el-select {
    width: 150px;
  }

  .el-input__inner {
    background: transparent;
    color: #fff;
    font-size: .9em;
    border: 1px solid #bfbdbd;
  }

  .el-select__caret {
    color: #fff;
  }
}
</style>
