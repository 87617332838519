<template functional>
  <el-dropdown
    trigger="click"
    @command="$options.command($event, parent)"
  >
    <div class="cursor-pointer">
      <el-avatar
        :size="40"
        :src="$options.picture(parent)"
        icon="el-icon-fa-user fad"
      />
    </div>

    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="logout">
        <fai icon="sign-out"/>

        Logout
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>

export default {
  picture(parent) {
    const { picture } = parent.$store.state.auth.user;

    if (!picture) {
      return null;
    }

    // TODO: Handle picture for file server

    // return `${config['storage-server']}/${picture}`;
    return 'hello';
  },

  command(command, parent) {
    if (command === 'logout') {
      parent.$auth.logout();
    }
  },
};
</script>
