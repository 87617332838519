import { emitter } from '@/plugins/emitter/emitter';
import { authManager } from '@/plugins/auth';
import { style } from '@/store/style.store';
import { SETTINGS_KEY } from '@/constants';
import { i18n } from '@/home/i18n';
import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    style,
  },
  state: {
    initialized: false,
    auth: {
      user: null,
    },
  },
  actions: {
    async initialized({ state }) {
      state.initialized = true;
    },

    async initialize({ state }) {
      const settingsStr = localStorage.getItem(SETTINGS_KEY);

      if (settingsStr) {
        const settings = JSON.parse(settingsStr);

        i18n.changeLocale(settings.lang);
      }

      emitter.on('auth-init', (user) => {
        state.auth.user = user;
      });

      emitter.on('auth-logout', () => {
        state.auth.user = null;
      });

      await authManager.initialize();
    },
  },
});
