import { SETTINGS_KEY } from '@/constants';
import { I18n } from '@/modules/i18n';
import Vue from 'vue';

import EN from '@/i18n/en/home';
import BN from '@/i18n/bn/home';

Vue.use(I18n);

export const i18n = new I18n({
  locales: [EN, BN],
  fallbackLocale: 'en',
});

i18n.addListener((lang) => {
  document.documentElement.setAttribute('lang', lang);

  // Update document title
  document.title = i18n.t('app-title');

  const settingsStr = localStorage.getItem(SETTINGS_KEY);

  if (settingsStr) {
    const settings = JSON.parse(settingsStr);

    settings.lang = lang;

    localStorage.setItem(
      SETTINGS_KEY,
      JSON.stringify(settings),
    );
  } else {
    localStorage.setItem(SETTINGS_KEY, `{"lang": "${lang}"}`);
  }
});
