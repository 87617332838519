import Home from '@/home/pages/home';
import VueRouter from 'vue-router';
import Vue from 'vue';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/home/pages/login'),
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/home/pages/register'),
    },
    {
      path: '/password-reset',
      name: 'password-reset',
      component: () => import('@/home/pages/password-reset'),
    },
    {
      path: '/blog',
      name: 'blog',
      component: () => import('@/home/pages/blog'),
    },
    {
      path: '/app',
      name: 'app',
      beforeEnter() {
        // eslint-disable-next-line no-restricted-globals
        window.open(`${location.protocol}//${location.host}/app`, '_self');
      },
    },
  ],
});

export default router;
