<template>
  <section>
    <intro-non-registered v-if="!$store.state.auth.user"/>
    <intro-registered v-else/>
  </section>
</template>

<script>
export default {
  components: {
    introNonRegistered: () => import('./intro-non-registered'),
    introRegistered: () => import('./intro-registered'),
  },
};
</script>
